import { Box, Grid, Heading, Text,Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,useDisclosure, FormLabel, Input,FormControl, Button } from "@chakra-ui/react";
import React from "react";
import TariffCard from "./TariffCard";
import MainCanvas from "./MainCanvas"

function TariffPage(props) {
    const { isOpen, onOpen, onClose } = useDisclosure()

    
  return (
    <Box margin={"20px"}>
      {/* <Box textAlign={"center"} mt={5}>
        <Heading fontFamily={'Mulish'} my={5}>Schools Data</Heading>
        <Text fontFamily={'Mulish'}>
          *Use the table filters at any column to search for a specific school.
        </Text>
      </Box> */}
      <Box mt={10} mb={10} alignSelf={"center"}>
        {/* <SchoolsTable/> */}
        <MainCanvas />
      </Box>
      {/* <Box mt={10} mb={10}>
        <Grid width={['95%','95%','80%']} m={"auto"} templateColumns={['repeat(1, 1fr)','repeat(1, 1fr)','repeat(2, 1fr)','repeat(2, 1fr)','repeat(3, 1fr)']} gap={6}>

        {
          tariffData.map((el,ind)=>{
            return <TariffCard onOpen={{}} key={ind} data={el}>

            </TariffCard>
          })
        }

            
        </Grid>
      </Box> */}
      {/* <Modal
        
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Search your next ride</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
           
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='blue' mr={3}>
              Save
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal> */}
    </Box>
  );
}

export default TariffPage;
