/* 
   This is the main map class which uses the Tomtom apis (Map Visualization, Routing and Search)
   to create an interactive map and render the routes as well as point locations of drivers thereto.
*/

// Import all dependancies to be used. NOTE: These dependancies must be install before import
import React, { Component, createRef, CSSProperties } from 'react';
import tt, {Control, CustomLayer} from '@tomtom-international/web-sdk-maps';
import { services } from '@tomtom-international/web-sdk-services';
import env from "react-dotenv";
import SearchBox from '@tomtom-international/web-sdk-plugin-searchbox';
import SearchMarkersManager from './SearchMarkerManager';
import '@tomtom-international/web-sdk-maps/dist/maps.css';
// import "https://api.tomtom.com/maps-sdk-for-web/cdn/plugins/SearchBox/3.1.12/SearchBox.css";
import MyLocationIcon from '@mui/icons-material/MyLocation';
import { store } from '../../Redux/store';
import { Input } from '@chakra-ui/react'
import {  List, ListItem } from "@mui/material";

/* 
  With the use of a custom class we create and export a Map component that can be used in other components
  within the project. 
      
*/
export class Map2 extends Component {

    constructor(props) {
        super(props)

        this.state = {
            TT_APIKEY: 'wNwKO5fgWhDOBLlrpOLeXaS6a5iES9Nh',
            searchList: [],
            markers: [],
            markerIsOn: false,
            drivers: [],
            searchCoord: [],
            routes: [],
            pinMarker: false,
            pinPointMarker: null,
            orders: [],
            modalOpen: false,
            providerLocation: [],
            customerLocation: [],
            driverLocation: [],
            driverConfig: [],
            driverPickupBatchCoordinates: [],
            bestRouteIndex: -2,
            routeWeight: 7,
            routeBackgroundWeight: 10,
            fastestRouteColor: 'green',
            pickupCoordinates: [],
            origins: [],
            destinations: [],
            colors: ["blue", "orange", "red", "purple", "black", "grey", "brown", "violet"],
            assignedClicked: false,
            mapMarkers: [],
            orderBtn: '',
            loadingDone: false,
            selectedOrder: [],
            allDrivers: [],
            pickup: [],
            start: [],
            dropOff: [],
            plannedRouteCoords: [],
            lock_position: {lat: '28.31339534205034', lon: '-25.75112170051615'},
            lock_lat: '',
            lock_lon: '',
            mylocation_icon2: '',
            farms: [],
            farmGeoms: [],
            marketer: [store.getState(state=>state.LoginSignupRed.name)],
            superUser: [store.getState(state=>state.LoginSignupRed.isAdmin)],
            allMarketers: [],
            marketerNames: [],
            marketersName: "",
            searchMarkersManager: new SearchMarkersManager(this.map),
            searchResults: [],
            assignItemsToList: false,
        }

        this.mapRef = createRef();
        this.searchLoc = this.searchLoc.bind(this);
        this.handleResult = this.handleResult.bind(this);
        this.moveMapTo = this.moveMapTo.bind(this);
        this.addMarker = this.addMarker.bind(this);
        this.addMarketerToMap = this.addMarketerToMap.bind(this);
        this.dropPinAtSearched = this.dropPinAtSearched.bind(this);
        this.dropSearch = this.dropSearch.bind(this);
        this.clearPinMarker = this.clearPinMarker.bind(this);
        this.buildStyle = this.buildStyle.bind(this);
        this.pageLoading = this.pageLoading.bind(this);
        this.showPosition2 = this.showPosition2.bind(this);
        this.getLocation2 = this.getLocation2.bind(this);
        this.addFarmGeoms = this.addFarmGeoms.bind(this);
        this.theMasterReload = this.theMasterReload.bind(this);
        this.changeFarmsLayer = this.changeFarmsLayer.bind(this);
        this.reloadFarmBoundaries = this.reloadFarmBoundaries.bind(this);
        this.getBounds = this.getBounds.bind(this);
        // this.openVisitationFromMap = this.openVisitationFromMap.bind(this);
    }

    // openVisitationFromMap() {
        
    //   // TODO

    // }

    theMasterReload() {

        // this.setState({
        //     loadingDone: false
        // })
        const APIKEY = this.state.TT_APIKEY;
        let marketerList=[];
        var marketerIndex=0;

        //console.log(this.state.marketer);

        const fetchMarketers = async () => {

            fetch(`https://jdi.market.geoint.africa/jdipwa/api/marketers`)
            .then((res) => res.json())
            .then((data) => {

                data.map((record) => {
                    let namedRecord = {id: marketerIndex, label: record.username, value: record.username};
                    
                    marketerList.push(namedRecord)

                    marketerIndex+=1;

                });
                
                // console.log(marketerList);
                this.setState({
                    allMarketers: marketerList
                })

            })
            .catch((error) => {
                console.log(error)
            })
        }
        // if user is a superuser call the function to get all marketers
        // console.log(this.state.marketer[0].LoginSignupRed.isAdmin)

        if(this.state.marketer[0].LoginSignupRed.isAdmin === true) {

            fetchMarketers()  
            .catch((error) => {
                console.log(error)
            });

            this.setState({
                marketerNames: marketerList
            })
        }
    

        this.map = tt.map({

            key: APIKEY,
            container: 'map2',
            language: 'en-GB',
            center: [22.718602, -32.159654],
            zoom: 5,
            pitch: 0,
            stylesVisibility: {
            
                points: true,
                poi: true,      

            },
            fadeDuration: 250,
            rotateDuration: 250,
            maxZoom: 19,
            trackResize: true,
            touchZoomRotate: this.forceUpdate,
        })

        this.getLocation2();

        this.map.addControl(new tt.GeolocateControl({
            positionOptions: {
                enableHighAccuracy: true
            },
            trackUserLocation: true,
            showUserHeading: true,
            fitBoundsOptions: {animate: true},
        
        }));

        const fetchData = async () => {
            fetch(`https://jdi.market.geoint.africa/jdipwa/api/farms/${this.state.marketer[0].LoginSignupRed.name}`)
            .then((res) => res.json())
            .then((data) => {

                this.addFarmGeoms(data)

                this.setState({
                    farmGeoms: [data]
                });
                // console.log(data)
                })
            .catch((error) => {
                console.log(error)
            })
        }
        // call the function
        fetchData()  
        .catch((error) => {
            console.log(error)
        }) 

        this.map.on('click', 'farm-boundaries', (e) => {
            var string_data = "<br> Business Name: "+e.features[0].properties.business_name+
                                "<br> Farm Name: "+e.features[0].properties.farm_name+
                                "<br> Farm Type: "+e.features[0].properties.farm_type+
                                "<br> Contact Person: "+e.features[0].properties.contact_name+
                                "<br> Farm Profile: "+e.features[0].properties.farm_profile+
                                "<br> LPI: "+e.features[0].properties.lpi+
                                "<br> Branch: "+e.features[0].properties.branch+
                                "<br> Farm Notes: "+e.features[0].properties.farm_comments
                                // "<br>"                                                         +
                                // "<br>"                                                         +
                                // "<button variant='light' onclick='openVisitationFromMap()' style='padding:4px; border-radius=4px'>Visit Now</button> "

            new tt.Popup()
                .setLngLat(e.lngLat)
                .setHTML("<center><img width='120px' src='/JDI_Logo_Main.png' /></center> <br/>" +string_data)
                .addTo(this.map);
        });

        // Change the cursor to a pointer when the mouse is over the states layer.
        this.map.on('mouseenter', 'farm-boundaries', (e) => {
            this.map.getCanvas().style.cursor = 'pointer';
            this.map.setPaintProperty('farm-boundaries', 'fill-color',[
                    'match',
                    ['get', 'farm_name'], e.features[0].properties.farm_name
                    , 'rgba(66,100,251, 0.4)',
                    /* other */ 'rgba(200, 100, 40, 0.4)'
                ]
            );
            
        });

        // Change it back to a pointer when it leaves.
        this.map.on('mouseleave', 'farm-boundaries', () => {
            this.map.getCanvas().style.cursor = '';
            this.map.setPaintProperty('farm-boundaries', 'fill-color',
            'rgba(200, 100, 40, 0.4)');
        });
    }


    componentDidMount() {
        this.theMasterReload();
    }

    // TODO: add color coded polygons for each marketer

    pageLoading() {

        let loading = this.state.loadingDone;
        
        this.setState({
            loadingDone: !loading
        });

        console.log(`Changing loading state to ${loading}`)

    }

    // fuzzy search results
    handleResult(result) {

        // property path: results[0].address.freeformAddress

        if (result.results.length >= 1 && result.results.length <= 10) {

            this.setState({
                assignItemsToList: true
            });
            this.setState({
                searchList: [...this.state.searchList, result.results[0]]
            });
        }

    }

    // search box
    async searchLoc() {
        const TT_APIKEY = this.state.TT_APIKEY

        this.setState({ searchList: [] });

        const response = await services.fuzzySearch({
            key: TT_APIKEY,
            query: document.getElementById('locationQuery').value,
            limit: 10,
            language: 'en-GB',
            typeahead: true,
            countrySet: 'ZAF'
        })
        this.handleResult(response)
    }

    async dropSearch() {
        await services.fuzzySearch({
            key: this.state.TT_APIKEY,
            query: document.getElementById('locationQuery').value,
            limit: 10,
            language: 'en-GB',
            typeahead: true,
        }).then((results) => {
            this.setState({
                searchCoord: Object.values(results.results[0].position)
            })
            // this.dropPinAtSearched(Object.values(results.results[0].position), false) //
            // console.log(results.results[0].position) 

        })

    }
 
    dropPinAtSearched(locay) {

        let markerCoordinates = locay;
        var rgRequest = new XMLHttpRequest();
        let result_data;
        let locationInfo;

        const pickupMarkerElement = document.createElement('div');
        let pinPointMark = this.state.pinPointMarker;
        pickupMarkerElement.className = "marker-icon";

        rgRequest.onreadystatechange = function(){
            if(this.readyState==4 && this.status==200){
                result_data = JSON.parse(rgRequest.responseText);
                var data = result_data.addresses[0].address;

                locationInfo = data.freeformAddress
            }

            return locationInfo;
        }
        var rgURL = "https://api.maps.geoint.africa/v1/reverseGeocode/"+markerCoordinates.lat+","+markerCoordinates.lng+".json?returnSpeedLimit=true"

        rgRequest.open("GET", rgURL, true);
        rgRequest.send();

        const addPinMarker = () => {

            pinPointMark = new tt.Marker({ element: pickupMarkerElement, offset: [0, 5], className: 'pinPointMarker'}).setLngLat(markerCoordinates);

            pinPointMark.addTo(this.map.setZoom(5).flyTo(markerCoordinates));

            var markerHeight = 50, markerRadius = 10, linearOffset = 25;
            var popupOffsets = {
                'top': [0, 0],
                'top-left': [0,0],
                'top-right': [0,0],
                'bottom': [0, -markerHeight],
                'bottom-left': [linearOffset, (markerHeight - markerRadius + linearOffset) * -1],
                'bottom-right': [-linearOffset, (markerHeight - markerRadius + linearOffset) * -1],
                'left': [markerRadius, (markerHeight - markerRadius) * -1],
                'right': [-markerRadius, (markerHeight - markerRadius) * -1]
            };
            new tt.Popup({offset: popupOffsets, className: 'searchPopup'})
                .setLngLat(markerCoordinates)
                .setHTML(`<b>Location Information</b> 
                    <br/>
                    <p>${locationInfo}</p>
                `)
                .addTo(this.map)

            // this.setState({
            //     mapMakers: [...this.state.mapMarkers, pinPointMark]
            // })


            this.setState({ pinPointMark: pinPointMark });

            this.moveMapTo(markerCoordinates)

            this.setState({
                pinMarker: true
            })
        }

        if (this.state.pinMarker === false) {
            
            setTimeout(() => {
                addPinMarker();
                this.setState({ searchList: [] });
            }, 500)
            
        } else {
            // this.map.remove(this.state.pinPointMark)

            // this.state.mapMarkers.forEach((marker) => {
            //     marker.remove()
            // })

            // this.setState({
            //     mapMarkers: []
            // })

            addPinMarker()
        }
    }


    addMarketerToMap() {
        let drivers = this.state.drivers;
        // let colors = ["blue", "green", "purple", "orange", "pink", "black", "white", "brown", "grey"]

        // this.updateDriverBatchLocations(this.state.pickupCoordinates)

        // eslint-disable-next-line array-callback-return

        drivers.map((data, index) => {

            // let coordLng = data[index].location.coordinates[0];
            // let coordLat = data[index].location.coordinates[1];
            // let swappedCoord = [coordLat, coordLng];
            let driverPosition = data[index].location.coordinates
            let driverStatus = data[index].status

            // console.log(driverPosition)
            this.addMarker(driverPosition, driverStatus === "available" ? "black" : "white")

            return true
        })

    }


    moveMapTo(location) {
        this.map.flyTo({
            center: location,
            zoom: 13
        })
    }


    addMarker(coord, color, popup) {
        let markers = this.state.markers;

        let mapMaker = new tt.Marker({ color: color }).setLngLat(coord).addTo(this.map)
        this.state.mapMarkers.push(mapMaker)

        if (popup !== undefined) {
            mapMaker.setPopup(popup).togglePopup()
        }

        this.setState({ markers: [...markers, coord] });
    }

    clearPinMarker() {

        this.map.remove(this.state.pinPointMarker)

        this.state.mapMarkers.forEach((marker) => {
            marker.remove()
        })

        this.setState({
            mapMarkers: []
        })

        if (this.state.pinMarker === true) {
            this.map.remove(this.state.pinPointMarker)
            // this.state.pinPointMarker.remove()

            this.map.setPitch(0);

            this.setState({
                pinMarker: false
            })
        }
        else {
            // Do nothing
        }
    }

    // ******************************************************** 

    modalState(e) {
        e.preventDefault()

        this.setState({
            modalOpen: true,
        })
    }

    // find my location
    getLocation2() {
        if (navigator.geolocation) {
            
            navigator.geolocation.getCurrentPosition(this.showPosition2);

            setInterval(() => {
                navigator.geolocation.getCurrentPosition(this.showPosition2);
            }, 100000)
            
        } else { 
            console.log("Geolocation is not supported by this browser.");
            alert("Geolocation may not be supported by this browser.")
            
        }
        
    }

    addFarmGeoms(sourceData) {
        
        let marketers =  {
            'Jaco Swanepoel': 'rgba(66,100,251, 0.2)',
            'Bertus Nel': 'rgba(251, 94, 66, 0.5)',
            'Dirk Sterley': 'rgba(92, 179, 35, 0.5)',
            'Nicolaas Strydom': 'rgba(201, 160, 25, 0.5)',
            'Henco Boshof': 'rgba(36, 43, 59, 0.5)',
            'Emile Tomlinson': 'rgba(243, 139, 6, 0.5)',
            null: 'rgba(255, 255, 255, 0.5)'};

        let marketerKeys = Object.keys(marketers);
        let marketerVals = Object.values(marketers);
            
        
        this.map.addSource('farm-boundaries', {

            type: 'geojson',
            data: sourceData,
            
        });

        // : function(feature) {
        //     switch (feature.properties.marketer) {
        //         case 'jaco':   return {fillColor:"red"};
        //         case 'henco':   return {fillColor:"blue"};
        //         case 'emile':   return {fillColor:"green"};
        //         case 'nicolaas':   return {fillColor:"yellow"};
        //         case 'bertus':   return {fillColor:"purple"};
        //         case 'dirk':   return {fillColor:"grey"};
        //         case 'jacod':   return {fillColor:"orange"};
        //         default: return {fillColor:"pink"}
        // }
        // }
        
        this.map.addLayer({
            id: 'farm-boundaries',
            type: 'fill',
            source: 'farm-boundaries',
            layout: {
            },
            paint: {
                // 'fill-color': 'rgba(47, 138, 167, 0.5)',
                'fill-opacity': 0.75,
                'fill-color': 'rgba(255, 124, 0, 0.5)',
                'fill-outline-color': 'rgba(255, 124, 0, 1)'
            },
            interactive: true,
        }); 

    }

        
    showPosition2(position2) {
        this.setState({
            lock_lat: position2.coords.latitude
        });
        this.setState({
            lock_lon: position2.coords.longitude
        });
        this.setState({
            lock_position: {lon: position2.coords.longitude, lat: position2.coords.latitude}
        });
        
        let markerCoordinates = `${position2.coords.latitude},${position2.coords.longitude}`

        if(this.state.lock_position !== undefined){
            //this.map.setView(markerCoordinates, 16);
            // map2.setView(-33.908025, 18.410333, 14);
            // console.log(this.state.lock_lat + ", " + this.state.lock_lon);
        }
        else {
            alert('Turn on your device location for a better navigation experience')
        }

    }


    drawAllRoutes(travelTimeInSecondsArray) {
        let driverPickupBatchCoordinates = this.state.driverPickupBatchCoordinates;
        let bestRouteIdx = travelTimeInSecondsArray.length - 1

        console.log('reached drawAllRoutes')
        // console.log(driverPickupBatchCoordinates)

        let driverLocays = [];

        driverPickupBatchCoordinates.map((loc) => (
            driverLocays.push({ locations: loc })

        ))

        services.calculateRoute({
            batchMode: 'sync',
            key: this.state.TT_APIKEY,
            batchItems: [
                ...driverLocays

                // { locations: driverPickupBatchCoordinates[0] },
                // { locations: driverPickupBatchCoordinates[1] },
                // { locations: driverPickupBatchCoordinates[2] },
                // { locations: driverPickupBatchCoordinates[3] }
            ]
        })
            .then((results) => {

                let routes = this.state.routes;
                const routeBackgroundWeight = this.state.routeBackgroundWeight;
                let routeWeight = this.state.routeWeight;
                let bestRouteIndex = this.state.bestRouteIndex;


                results.batchItems.map((singleRoute, index) => {
                    let routeGeoJson = singleRoute.toGeoJson();
                    let route = [];
                    let route_background_layer_id = 'route_background_' + index;
                    let route_layer_id = 'route_' + index;
                    const colors = this.state.colors;

                    this.map.addLayer(this.buildStyle(route_background_layer_id, routeGeoJson, 'black', routeBackgroundWeight))
                        .addLayer(this.buildStyle(route_layer_id, routeGeoJson, index === bestRouteIndex ? 'green' : "yellow", routeWeight))
                        .setPitch(0);


                    route[0] = route_background_layer_id;
                    route[1] = route_layer_id;
                    routes[index] = route; // this was routes[index] 

                    if (index === bestRouteIndex) {
                        const bounds = new tt.LngLatBounds();
                        routeGeoJson.features[0].geometry.coordinates.map((point) => {
                            bounds.extend(tt.LngLat.convert(point));
                            return true
                        });
                        this.map.fitBounds(bounds, { padding: 150 });
                    }

                    this.map.on("mouseenter", route_layer_id, () => {
                        this.map.moveLayer(route_background_layer_id);
                        this.map.moveLayer(route_layer_id);
                    });

                    this.map.on("mouseleave", route_layer_id, function () {
                        this.bringBestRouteToFront();
                    });

                    return true
                });
                this.bringBestRouteToFront();
                this.displayModal();
            });
    }


    bringBestRouteToFront() {
        let routes = this.state.routes;
        let bestRouteIndex = this.state.bestRouteIndex;

        this.map.moveLayer(routes[bestRouteIndex][0]);
        this.map.moveLayer(routes[bestRouteIndex][1]);
    }

    buildStyle(id, data, color, width) {

        console.log('reached buildStyle')

        return {
            'id': id,
            'type': 'line',
            'source': {
                'type': 'geojson',
                'data': data
            },
            'paint': {
                'line-color': color,
                'line-width': width
            },
            'layout': {
                'line-cap': 'round',
                'line-join': 'round'
            }
        }
    }

    changeFarmsLayer = (e) => {

        let selectedMarketer = document.getElementById("marketersList").value;

        let farmBoundaries = this.map.getLayer('farm-boundaries');

        this.props.marketerName = selectedMarketer;

        // dispatch({
        //     type: "FARMSFILTER",
        //     payload: {"viewFarms":selectedMarketer}
        // });        

        if(farmBoundaries !== undefined) {

            // remove current layer
            this.map.removeLayer('farm-boundaries');

            // remove current map source
            this.map.removeSource('farm-boundaries');

            // add new farm boundary layer with the new source (using the marketers name as a filter value)
            const fetchData = async () => {
                fetch(`https://jdi.market.geoint.africa/jdipwa/api/farms/${selectedMarketer}`)
                .then((res) => res.json())
                .then((data) => {

                    this.addFarmGeoms(data)

                    this.setState({
                        farmGeoms: [data]
                    });
                    // console.log(data)
                    })
            }
            fetchData()
            .catch((err) => {
                console.error(err)
            });
        }else {
            const fetchData = async () => {
                fetch(`https://jdi.market.geoint.africa/jdipwa/api/farms/${selectedMarketer}`)
                .then((res) => res.json())
                .then((data) => {

                    this.addFarmGeoms(data)

                    this.setState({
                        farmGeoms: [data]
                    });
                    // console.log(data)
                    })
            }
            fetchData()
            .catch((err) => {
                console.error(err)
            });
        }
    } 

    reloadFarmBoundaries = async () => {
        const fetchData = async () => {
            fetch(`https://jdi.market.geoint.africa/jdipwa/api/farms/${this.state.marketer[0].LoginSignupRed.name}`)
            .then((res) => res.json())
            .then((data) => {

                // if(this.state.marketer[0].LoginSignupRed.isAdmin === true) {
                //     // Don't Plot Farms
                // }
                // else{
                this.addFarmGeoms(data)
                // }

                this.setState({
                    farmGeoms: [data]
                });
                // console.log(data)
                })
            .catch((error) => {
                console.log(error)
            })
        }
        // call the function
        fetchData()  
        .catch((error) => {
            console.log(error)
        })
    }
      
    getBounds = (data) => {

        var btmRight;

        var topLeft

        if (data.viewport) {
            btmRight = [
            data.viewport.btmRightPoint.lng,
            data.viewport.btmRightPoint.lat,
            ]
            topLeft = [data.viewport.topLeftPoint.lng, data.viewport.topLeftPoint.lat]
        }
        return [btmRight, topLeft]
    }
    
    render() {

        const { marketer, loadingDone, searchResults, searchList, pinMarker, assignItemsToList } = this.state;

        let isAdmin = marketer[0].LoginSignupRed.isAdmin;
        
        // const loading = orderCount > 0 ? true : false;
        if(!loadingDone){
        return (    
            <>

            <div className="col-sm-7 col-lg-7 column" id="mapCell">

                {/* <h4 className='grid-heading'>Map </h4> */}

                <div style={{ height: '100%' }}>
                    <div ref={this.mapRef} id="map2" style={{ position:'relative', height: '100%', borderRadius: '1px' }}>
                        {/* map goes here */}
                        <div className="mapSearch" style={{ zIndex: 999, position: 'absolute', left: 10, top: 30, height: '8%' }}>
                        <Input 
                            type="search" 
                            list="mapSearchText" 
                            id="locationQuery" 
                            className="form-control" 
                            onKeyUp={this.searchLoc} 
                            placeholder="Search location" 
                            style={{
                                width: 'auto', 
                                zIndex: 999,
                                backgroundColor:'white'
                            }}
                        />
                        {/* property path: results[0].address.freeformAddress */}
                        {searchList.length <= 10 ? (
                            <ul id="mapSearchText">
                                {searchList.map((data, idx) => (
                                    <li key={idx} style={{
                                        fontSize: '14px',
                                        minWidth: '72%', 
                                        maxWidth: '72%', 
                                        padding: '4px', 
                                        border: '1px solid gray', 
                                        borderRadius: '4px',
                                        backgroundColor: '#1a2846',
                                        color: 'white',
                                        marginLeft: '-9%'
                                        
                                    }}>
                                        <button 
                                            key={idx}
                                            value={data.position}
                                            onClick={e => this.dropPinAtSearched(data.position)} 
                                            style={{
                                                fontSize: '14px',
                                                maxWidth: '100%', 
                                                color: 'white',
                                                // backgroundColor: '#1a2846',
                                                
                                            }}
                                        >
                                            {`${data.address.freeformAddress}`}
                                        </button>
                                    </li>
                                ))}
                            </ul>)
                            : null
                            }
                        </div>
                        <div className="labels-container" style={{ zIndex: 1, position: 'absolute', right: 10, top: 40, height: '12%' }}>
                            <div type="button" id="submit-button" value="" onClick={this.addMarketerToMap}
                                style={{
                                    cursor: 'pointer',
                                    color: 'slategray', 
                                    
                                }}
                            >
                                <strong>Track</strong>
                            </div>
                        </div>
                        {
                            isAdmin === true
                            ?
                            (
                            <div 
                                className="form-group has-search" 
                                style={{ paddingTop: '0.5rem', position: 'absolute', marginLeft: "10px", zIndex: 1, width: "40%" }}
                            >

                            <select
                                className="basic-single"
                                placeholder="Filter by maketer"
                                isClearable={true}
                                id="marketersList"
                                // isRtl={isRtl}
                                isSearchable={true}
                                name="marketers"
                                onChange={this.changeFarmsLayer}
                                // onInput={}
                                style={{
                                    width: "90%",
                                    height: "30px",
                                    padding: "4px 6px",
                                    margin: "8px",
                                    marginLeft: "8px",
                                    marginTop: "65px",
                                    display: "inline-block",
                                    border: "1px solid #4D862D",
                                    borderRadius: "4px",
                                    boxSizing: "border-box",
                                    zIndex: 1,
                                    position: "absolute",
                                    backgroundColor: "#4D862D",
                                    color: "ghostwhite",
                                    fontWeight:"bold"
                                }}
                            >
                                <option value="">Farm Filter</option>
                                <option value="bertus">Bertus Nel</option>
                                <option value="jaco">Jaco Swanepoel</option>
                                <option value="dirk">Dirk Sterley</option>
                                <option value="henco">Henco Boshof</option>
                                <option value="nicolaas">Nicolaas Strydom</option>
                                <option value="emile">Emile Tomlinson</option>
                                <option value="arnold">Arnold Jordaan</option>
                                <option value="emilius">Emilius Tomlinson</option>
                                <option value="driaan">Driaan Schwartz</option>
                                <option value="jason">Jason Tomlinson</option>
                                <option value="jacod">Jaco Dippenaar</option>
                                <option value="kaylan">Geoint Admin</option>
                                
                            </select>
                            
                            
                            
                        </div>


                        )
                        :
                        (
                            
                        <input  
                            type="button" 
                            onClick={this.reloadFarmBoundaries} 
                            style={{
                                borderStyle:"none", 
                                backgroundColor:"white", 
                                color: "darkgreen", 
                                position: "absolute",
                                fontWeight: "bold",
                                fontFamily:"monospace",
                                zIndex: 99,
                                height: '30px',
                                width: "25%",
                                marginLeft: "10px",
                                marginTop: '72vh',
                                borderRadius: "5px",
                            }}
                            value="load farms"
                        >       
                        </input> 
                        )
                    } 
                    
                    </div>

                </div>
            </div> 
        </>
            
    )}
    }
}

export default Map2;