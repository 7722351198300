import React, { useEffect } from "react";
import {
    Box, Center, FormControl, FormLabel, Input, Flex,
    InputGroup, InputRightElement,
    Button, Heading, useToast, Text, CircularProgress, use, InputAddon, Spacer
} from '@chakra-ui/react';
import ReCAPTCHA from "react-google-recaptcha";
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons'
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom"; 

function Login(props) {

    // states for collecting data 
    const [username, setUser] = useState("");
    const [password, setPassword] = useState("");
    const [userError, setUserError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [isCaptchCheckedIn, setIsCaptchaCheckedIn] = useState(false);
    const [superUser, setSuperUser] = useState(false);

    const nav = useNavigate()

    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [storedData, setUserData] = useState([]);

    useEffect(() => {
        setIsLoggedIn(true)

    }, [isLoggedIn])


    const dispatch = useDispatch();

    const toast = useToast()

    const login = isLoggedIn

    console.log("checking login value",login)
    // it is for hide and show password
    const [show, setShow] = React.useState(false)

//<<<<<<<<< Trying redirecting to last path >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
// const navigate = useNavigate();
// const location = useLocation();
// const fromPathname = location?.state?.from?.pathname || '/';

    
//<<<<<<<<< Ending Trying redirecting to last path >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
    const handleClick = () => setShow(!show)

    // it is for captcha implementation
    function onChange(value) {
        // console.log("Captcha value:", value);
        setIsCaptchaCheckedIn(true)
    }
    // it is for forget passowrd functioanality
    const FogetPassword = () => {
        // nav('/forgetPage')
        if (username.includes("@") && (!isCaptchCheckedIn)) {
            return toast({
                position: 'top-right',
                title: 'Please verify capthca',
                // description: "Welcome to MapstackUJ.com",
                status: 'warning',
                duration: 2000,
                isClosable: true,
            })
        } if (username.length === 0) {
            return toast({
                position: 'top-right',
                title: 'Enter user email address',
                // description: "Welcome to MapstackUJ.com",
                status: 'warning',
                duration: 2000,
                isClosable: true,
            })
        } if (username.includes("@") && isCaptchCheckedIn) {   
            
        }
    }


    const CheckUserDetails = () => {

        // check user credentials at API
        fetch("https://jdi.market.geoint.africa/jdipwa/api/user/login", {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify({'username': username, 'password': password})
        })
        .then(res => res.json())
        .then((data) => {
            
            if(data.success) {
                setUserData({'username': data.username, 'password': data.password, 'id': data.userId, 'isAdmin': data.isAdmin});
                setIsLoggedIn(true);
                nav('/farms')
                // console.log(isLoggedIn)
                dispatch({
                    type: "ISLOGGEDIN",
                    payload: {"login":true, "name": username, "password": password, "super_user": data.isAdmin}
                });
                return toast({
                    position: 'top-right',
                    title: 'LoggedIn successfully.',
                    description: "Welcome to JDI Marketing",
                    status: 'success',
                    duration: 2000,
                    isClosable: true,
                });
            }
            else{
                return toast({
                    position: 'top-right',
                    title: 'User could not be authenticated. Please contact your administrator.',
                    // description: "Welcome to RoyalBrothers.com.",
                    status: 'error',
                    duration: 2000,
                    isClosable: true,
    
                })
            }
        })

        if (username === storedData.username && password === storedData.password
            && (isCaptchCheckedIn)) {

            console.log(isLoggedIn)
            dispatch({
                type: "ISLOGGEDIN",
                payload: {"login":login, "name": username, "password": password}
            });

            // console.log(UserDetailsObj)
                
            // dispatch({
            //     type: "SIGNUP",
            //     payload: UserDetailsObj
            // })

    
            
        } else if (username.includes("@") && password.length < 6 && (!isCaptchCheckedIn)) {
            return toast({
                position: 'top-right',
                title: 'Please verify capthca',
                // description: "Welcome to RoyalBrothers.com.",
                status: 'warning',
                duration: 2000,
                isClosable: true,
            })
        } else if (!username.includes("@") && password.length === 0 && (!isCaptchCheckedIn)) {

            return toast({
                position: 'top-right',
                title: 'Enter user details',
                // description: "Welcome to RoyalBrothers.com.",
                status: 'info',
                duration: 2000,
                isClosable: true,

            })
        }
        else {
            // return toast({
            //     position:'top-right',
            //     title: 'Invalid phone/password combination',
            //     // description: "Welcome to RoyalBrothers.com.",
            //     status: 'error',
            //     duration: 2000,
            //     isClosable: true,

            // })
        }
    }

    const handleUser = (e) => {
        let user = e.target.value
        if (user.length > 0) {
            setUserError(false)
        } else {
            setUserError(true)
        }
        setUser(user)
    }

    const handlePassword = (e) => {
        let password = e.target.value
        if (password.length === 0) {
            setPasswordError(true)
        } else {
            setPasswordError(false)
        }
        setPassword(password);
    }

    // it is for login by using otp functionality
    // const LoginWithOtp = () => {

    // }

    return (
        <Center display={'flex'} style={{overflow: 'hidden'}}>
            <Box maxW='70vw' w={'auto'} h={'-webkit-fit-content'} boxShadow='2xl' style={{background: "linear-gradient(-45deg, #39454be1 20%, #101017d5)", padding: '20px'}}>

                <FormControl >

                    <FormLabel>
                        <Box mt='25px' pl='35px' justify='right' style={{color:"ghostwhite"}}><b>User Login</b></Box>
                    </FormLabel>
                    <br/>
                    <Flex direction='column' p='0px 25px 0px 25px'>

                        <InputGroup>
                            <Flex>
                                {/* <InputLeftAddon children='+91' /> */}
                                <Input 
                                    id="username_input"
                                    type="text"
                                    width='100%'
                                    value={username} 
                                    onChange={handleUser}
                                    variant='outlined'
                                    placeholder="Username"
                                    h={'2rem'}
                                />
                            </Flex>
                        </InputGroup>
                        {/* it is for showing phone number error */}
                        <Box mb='30px'>
                            {
                                (userError) ? <Text fontSize='xs' color='red'>User name cannot be blank</Text> : ""
                            }
                        </Box>

                        <InputGroup size='md'>

                            <Input
                                id="passcode_input"
                                mr=''
                                type={show ? 'text' : 'password'}
                                variant='outlined'
                                placeholder='Password'
                                value={password} 
                                onChange={handlePassword}
                                width='70%'
                                h={'2rem'}
                            />
                            <InputRightElement width='4.5rem'>

                                <Button h='1.5rem' size='sm' onClick={handleClick}>
                                    {show ? <ViewOffIcon /> : <ViewIcon />}
                                </Button>
                            
                            </InputRightElement>
                        
                        </InputGroup>
                        {/* it is for showing password error */}
                        <Box mb='50px'>
                            {
                                (passwordError) ? <Text fontSize='xs' color='red'>Password cannot be blank</Text> : ""
                            }
                        </Box>

                        
                        {/* <Flex h="-webkit-fit-content" id="reCaptcha" flexShrink='20' maxW='70%'>
                            <Box borderRadius='3px'>
                                <ReCAPTCHA
                                    sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                                    onChange={onChange}
                                    style={{maxWidth: '90%'}}
                                />
                            </Box>
                        </Flex> */}
                        
                        <Box 
                            as='button' 
                            h='3rem' 
                            w='56'
                            bg='#4D862D' 
                            mt='0'
                            margin
                            borderRadius='4px'
                            onClick={CheckUserDetails}
                            alignSelf={"center"}
                            
                            // paddingLeft='10'
                            // paddingRight='10'
                            // padingBottom='2'
                            pt='3'
                        >
                            <Center alignContent={'center'}  h='100%'>
                                <Heading fontSize={'lg'} style={{color: "#EEEEEE", alignSelf:'center'}}>Login</Heading>
                            </Center>
                        </Box>

                    </Flex>
                    {/* <Center mb='8px'>OR</Center> */}
                    {/* <Center>
                        <Link to='/loginwithotp'>
                            <Box bg='#E8E8E8' as='button' h='50px' w='325px' >
                                <Center h='50px'><Heading size='sm' onClick={() => {
                            toast({
                                title: 'an OTP has been sent to you phone!',
                                description: "Chill man",
                                status: 'success',
                                duration: 3000,
                                isClosable: true,
                            });
                            setTimeout(() => {
                                return nav("/forgetpage")

                            }, 3000); // Redirect after 3 seconds
                        }}>Login with OTP</Heading></Center>
                            </Box>
                        </Link>
                    </Center> */}
                </FormControl>

            </Box>

        </Center >
    )
}
export default Login;