import styled from "styled-components";
import ClockWidget from "./ClockWidget";
import SearchForm from "./SearchForm";
import Login from "./Login/login";

function CTA(props) {
    return (
        <Container>
            {/* <SearchForm /> */}
            {/* <ClockWidget /> */}
            <Login />
        </Container>
    );
}

const Container = styled.div`
    background-image: url("../jdi_hres_banner.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 20px;

    @media (max-width:600px) {
        flex-direction: row;
        align-items: center;
    }
`;

export default CTA;