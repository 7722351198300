import "./App.css";
import Login from "./Components/Login/login"
import { useSelector } from 'react-redux';
import Home from "./Components/Pages/Home";
import { Routes, Route, Navigate } from "react-router-dom";
import Navbar from "./Components/Navbar/Navbar2";
import PrivateRoute from "./Components/PrivateRoute/PrivateRoute";
import TariffPage from "./Components/tariff_page/TariffPage";
import ResetPassword from "./Components/Login/ResetPassword";

// get user login state from redux 


function App() {

  const loggedIn = useSelector(state=>state.LoginSignupRed.isLoggedIn);

  
  return (
    <div className="App">
      {/* <Banner title="This application is powered by MAPSTACK" /> */}
      <Navbar />
      <Routes>
        {/* <Route path="/" element={<Home />} /> */}
        <Route exact path="/" element={<Navigate to={loggedIn ? "/farms" : "/login"} replace />}/>
        <Route path="/farms" element={<PrivateRoute><TariffPage /></PrivateRoute>} />
        <Route path="/login" element={<Home />} />
      </Routes>

      {/* <Footer /> */}
    </div>
  );
}

export default App;
