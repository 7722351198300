import { useState, useEffect } from "react";
import { useFormStatus } from "react-dom";
import { useDispatch } from "react-redux";
import {
    Button,
    Input,
    Box,
    Text,
    useToast,
    Collapse,
    useDisclosure,
    Divider
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons"
import styled from "styled-components";
import NavigationIcon from '@mui/icons-material/Navigation';
import { services } from '@tomtom-international/web-sdk-services';
import { InputLabel, List, ListItem } from "@mui/material";
import MyLocation from "@mui/icons-material/MyLocation";
import LoadingButton from '@mui/lab/LoadingButton';

// styling for Modal
const style = {
    position: 'absolutes',
    top: '80%',
    left: '50%',
    // transform: 'translate(-50%, -50%)',
    width: "80vw",
    bgcolor: 'background.paper',
    borderColor: '2px solid #000',
    boxShadow: 24,
    p: 4,
    overflowY: 'scoll'
  };

function CheckIn() {
    // const [isOpen, setIsOpen] = useState(false);
    // const [searchTerm, setSearchTerm] = useState("");

    const [searchResults, setSearchResults] = useState([]);

    const [latlonResults, setLatlonResults] = useState([]);

    const [locationFound, setLocationFound] = useState("");

    const [selectedLocation, setSelectedLocation] = useState([]);

    const [marketerPosition, setPosition] = useState({});

    const [rgData, setRgData] = useState([]);

    const TT_APIKEY = 'wNwKO5fgWhDOBLlrpOLeXaS6a5iES9Nh';

    const toast = useToast();

    const { isOpen, onToggle } = useDisclosure()

    const dispatch = useDispatch();


    useEffect(() => {
        setLocationFound("False");
    }, [locationFound]);


    const checkIn = () => {
        // check that the reverse geocode data has been retrieved a
        // and then confirm with user to trigger off checkIn post to DB/API 
        let locationConfirmation;
        let addressInfo;
        let fullAddressInfo;

        // console.log(rgData.length);
        
        if(rgData.length === 1) {

            if(!rgData[0].addresses[0]) {
                locationConfirmation = window.confirm
                (`Verify your location information
${rgData[0].results[0].address.freeformAddress}
                `);

                addressInfo = rgData[0].results[0].address.freeformAddress;
                fullAddressInfo = rgData[0].results[0];
            }
        
            locationConfirmation = window.confirm
            (`Verify your location information below

${rgData[0].addresses[0].address.freeformAddress}
            `);

            addressInfo = rgData[0].addresses[0].address.freeformAddress;
            fullAddressInfo = rgData[0].addresses[0]
            
            if(locationConfirmation) {

                setLocationFound("True");

                onToggle(false)

                dispatch({
                    type: "ISCHECKEDIN",
                    payload: {
                        "isCheckedIn":true, 
                        "geolocation": JSON.stringify(marketerPosition), 
                        "geoAddress": JSON.stringify(fullAddressInfo)
                    }
                });

                return toast({
                    position: 'top-right',
                    title: 'Check In - Close to reset ONLY.',
                    description: `@ ${addressInfo}`,
                    status: 'success',
                    duration: null,
                    isClosable: true,
                    onCloseComplete: (() => setRgData([])),
                    styleConfig:{getComputedStyle}
                });

            } else {

                dispatch({
                    type: "ISCHECKEDOUT"
                });

                return toast({
                    position: 'top-right',
                    title: 'JDI Marketing - CheckIn',
                    description: "Check in cancelled - close this to reset",
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                    onCloseComplete: (() => setRgData([]))

                });
            }
        } 
        
    };

    const checkInWithSearch = (dataIn) => {
        // check that the reverse geocode data has been retrieved a
        // and then confirm with user to trigger off checkIn post to DB/API 

        let locationConfirmation;
        let addressInfo;
        let fullAddressInfo;
        let coordInfo;
        let data = dataIn;

        // console.log(data[0])

        setSearchResults([]);
        setLatlonResults([data[0].position])

        if(!data[0].address.freeformAddress) {

            locationConfirmation = window.confirm
            (`Verify your location information
${data[0].address.freeformAddress}
            `);

            // Property Path: results[0].address.freeformAddress
            addressInfo = data[0].address.freeformAddress;
            fullAddressInfo = data[0].address;
            // coordInfo = latlonResults[0];
            coordInfo = data[0].position;
        }
        else {
            locationConfirmation = window.confirm
        (`Verify your location information below

${data[0].address.freeformAddress}
        `);

            addressInfo = data[0].address.freeformAddress;
            fullAddressInfo = data[0].address
            // coordInfo = latlonResults[0];
            coordInfo = data[0].position;
        }
        
        if(locationConfirmation) {

            // TODO: Post to DB

            onToggle(false)

            // console.log(searchResults[0].position)
            // console.log({lat: coordInfo.lat, lon: coordInfo.lng})

            dispatch({
                type: "ISCHECKEDIN",
                payload: {
                    "isCheckedIn":true, 
                    "geolocation": JSON.stringify({lat: coordInfo.lat, lon: coordInfo.lng}), 
                    "geoAddress": JSON.stringify(fullAddressInfo)
                }
            });

            return toast({
                position: 'top-right',
                title: 'Check In - Close to reset ONLY.',
                description: `@ ${addressInfo}`,
                status: 'success',
                duration: null,
                isClosable: true,
                onCloseComplete: (() => setRgData([])),
                styleConfig:{getComputedStyle},
                size:'small'
            });

        } else {

            dispatch({
                type: "ISCHECKEDOUT"
            });

            return toast({
                position: 'top-right',
                title: 'JDI Marketing - CheckIn',
                description: "Check in cancelled - close this to reset",
                status: 'error',
                duration: null,
                isClosable: true,
                onCloseComplete: (() => setRgData([]))

            });
        }
        
    };

    const handleCitySelect = (city) => {
        // setIsOpen(false)
        setSelectedLocation(city)
    }

    // fuzzy search results
    const handleResult = (result) =>{
        console.log(result)
        // console.log(result.results[0].address);
        if (result.results.length >= 1 && result.results.length <= 10) {
            setSearchResults([result.results[0]]);
            // setLatlonResults([result.results[0]]);
        }
    }

    // search box
    const searchLoc = async () => {
        
        setSearchResults([]);

        const response = await services.fuzzySearch({
            key: TT_APIKEY,
            query: document.getElementById('locationQuery2').value,
            limit: 10,
            language: 'en-GB',
            typeahead: true,
            countrySet: 'ZAF'
        })
        handleResult(response)
    }

    const showPosition = (position) => {

        let marketerCoordinates = {lon: position.coords.longitude, lat: position.coords.latitude};

        // declare the data fetching function
        const reverseGeocode = async () => {

            fetch(`https://geofusionapi.geoint.africa/hrg/v1.0/rg?lat=${marketerCoordinates.lat}&lon=${marketerCoordinates.lon}&verbose=2&api_key=FSQNHssAPh7M6cCNv467XtfpCaUV4RFLjFyMe2p2`, {
                
            })
            .then(res => res.json())
            .then((data) => {
                console.log(data)
                setRgData([data])
                setPosition(marketerCoordinates)
            });
        }
        reverseGeocode()
        .then(() => {
            checkIn()
        })
        .catch(console.error)
           
    }

    // find my location
    const getLocation = async () => {

        setLocationFound("Pending");

        try {
            if (navigator.geolocation) {

                // let locationPermission = window.confirm("Do you consent to share your location information with our site?");
                // if(locationPermission) {
                navigator.geolocation.getCurrentPosition(showPosition);
                // }
                
            } else { 
                console.log("Geolocation is not supported by this browser.");
                alert("Geolocation may not be supported by this browser. Please check that you have enabled your device location setting.")
                
            }
            
        } catch (error) {
            console.log(error.message);
            alert("Geolocation may not be supported by this browser. Please check that you have enabled your device location setting.");
        }
    }
     

    return (
        <>
            <Button variant="contained" onClick={onToggle} id='checkButton' style={{width: "auto", padding:"2px"}}>
                                            
                <NavigationIcon sx={{ mr: 1 }} />
                    Check in
            </Button>
            {/* <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} size="4xl" closeOnOverlayClick={false} sx={style} >
                <ModalOverlay />
                <ModalContent w="80%"> */}
                {/* <Button onClick={() => setIsOpen(false)}>Close</Button> */}

                <Collapse in={isOpen} animateOpacity fluid style={{minWidth:"40%"}}>
                    <Box
                        p='20px'
                        color='white'
                        mt='4'
                        bg='green.500'
                        rounded='md'
                        shadow='md'
                        minW="30"
                        
                    >
                        
                        {/* <WiggleText color='white'>
                            Free form Address Search 
                        </WiggleText>   */}
                        <>
                            <Input 
                                type="search" 
                                list="searchText" 
                                id="locationQuery2" 
                                className="form-control" 
                                onKeyUp={searchLoc} 
                                placeholder="Search location" 
                                style={{width: 'auto', maxWidth: '100%'}}
                            />
                            {searchResults.length <= 10 ? (
                                <ul id="searchText">
                                    {searchResults.map((data, idx) => (
                                        <li 
                                            key={idx} 
                                            style={{marginLeft:'-30px'}}
                                        >
                                            <button 
                                                value={[data.address]}
                                                onClick={e => checkInWithSearch([data])} 
                                                style={{
                                                    minWidth: '100%', 
                                                    maxWidth: '100%', 
                                                    padding: '2px', 
                                                    border: '1px solid white', 
                                                    borderRadius: '2px'}}
                                            >
                                                {`${data.address.freeformAddress}`}
                                            </button>
                                        </li>
                                    ))}
                            </ul>)
                            : null
                            }
                        </>
                        <Button 
                            onClick={checkInWithSearch} 
                            variant="contained" 
                            style={{color: 'white', 
                                    backgroundColor: 'blue', 
                                    borderRadius: '10%', 
                                    minWidth:'40%',
                                    marginTop:'10px',
                                    padding: '3px'}}>
                                        <b>search</b>
                        </Button>
                        
                        <Divider orientation='horizontal' />  

                        <Text color='white' fontWeight='bold'>
                                OR
                        </Text>
                        <Divider orientation='horizontal' />            
                            <WiggleText color='white' >
                                Double click the icon below
                            </WiggleText>     

                            
                            {locationFound === "False" ?
                            <Button variant="unstyled" id="submit-button" onClick={getLocation}
                                style={{
                                    cursor: 'pointer',
                                    color: 'white', 
                                    backgroundColor: 'orange', 
                                    borderRadius: '50%', 
                                    
                                    padding: '5px'
                                }}
                            >
                                
                                    <MyLocation/> 
                                    
                            </Button>
                            : locationFound === "Pending" 
                            ? (
                                <Button 
                                    variant="contained" 
                                    style={{color: 'white', 
                                            backgroundColor: 'grey', 
                                            borderRadius: '10%', 
                                            minWidth:'40%',
                                            marginTop:'10px',
                                            padding: '3px'}}>
                                                <b>Searching...</b>
                                </Button>
                            )
                            : <MyLocation/>
                        }
                        
                    </Box>
            
                </Collapse>
                    
        </>
    );
}


const WiggleText = styled.p`


    &:hover{
        animation: wiggle 1.5s infinite;
    }


    @keyframes wiggle {
    0% { transform: rotate(0deg); }
   80% { transform: rotate(0deg); }
   85% { transform: rotate(5deg); }
   95% { transform: rotate(-5deg); }
  100% { transform: rotate(0deg); }
}

`;


export default CheckIn;


