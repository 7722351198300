import styled from "styled-components";
import { Button, Select } from '@mantine/core';
import { useEffect, useState } from "react";

import { Center, Grid, GridItem, Heading, List, ListItem, StackDivider, Text, VStack } from '@chakra-ui/react';
import { Card, CardHeader, CardBody, CardFooter } from '@chakra-ui/react'
import { useSelector } from "react-redux";
import FarmsTable from "./tariff_page/FarmsTable";

function Fleet(props) {

    const [profile, setProfile] = useState('');
    const isDark = useSelector(state=>state.themeReducer.dark);
    const [marketers, setMarketers] = useState([]);
    const [fetching, setFetching] = useState(true);
    const [branches, setbranches] = useState([]);
    const [farms, setFarms] = useState([]);
    const [selectedBranch, setSelectedBranch] = useState('');
    const [selectedRepresentative, setSelectedRep] = useState('');
    const [selectedFarm, setSelectedFarm] = useState('');
    const [searchFarm, setSearchFarm] = useState([]);

    const resetAll = () => {
        setSelectedBranch('');
        setSelectedFarm('');
        setSelectedRep('');
        setProfile('');
        setFetching(true);
    }


    useEffect(() => {

        let branchHolder = [];

        if(fetching){
            
            const getMarketers = async() => {
                try {
                    fetch('https://jdi.market.geoint.africa/jdipwa/api/marketers')
                    .then((res) => res.json())
                    .then((data) => {
    
                    console.log(marketers)
                    let filteredData = data.map((record) => {
    
                        return record.username
                    })
                    
                    setMarketers(filteredData);
                    
                    
                    let filteredBranches = data.map((record, idx) => {
                        
                        return record.branch
                    })
    
                    setbranches(filteredBranches)
                    })
                } catch (error) {
                    console.error(error)
                }
            }
            getMarketers()


            const getFarms = async () => {

                try {
                    fetch('https://jdi.market.geoint.africa/jdipwa/api/farms/all')
                    .then((res) => res.json())
                    .then((data) => {
                        let theData = data.features;
    
                        let filteredData = theData.map((record, idx) => {
    
                            return record.properties
                        })
    
                        setFarms(filteredData)
                    })
                    .then(() => {
                        setFetching(false)
                    })
                } catch (error) {
                    console.error(error)
                }

            }
            getFarms()
                
        
        // setbranches(branchHolder)

    }

    }, fetching)

    const updateBranchAssignment = () => {
        // TODO 
    }

    const filterFarmsByProfile = () => {
        // TODO
    }

    return (

        <Container isDark={isDark}>
            <Heading size={'md'} textAlign="center" color={'darkgrey'}>Admin</Heading>

            <Button 
                size='small' 
                type='reset' 
                onClick={resetAll} 
                style={{color:'orangered', borderRadius:'3px', backgroundColor:'orangered', alignSelf:'flex-end'}}
                   
            >
                    reset all options
            </Button>
           
            <Grid templateColumns={
                {
                    base: "repeat(1, 1fr)",
                    md: "repeat(2, 1fr)",
                    lg: "repeat(3, 1fr)"
                }
            } gap={5} mt="20px">
                <GridItem w='100%'>
                    <Card >
                        <CardHeader>
                            <Text FontSize={'sm'}>
                                Use the drop downs below to assign a JDI Sales Representative to a farm or multiple farms belonging 
                                either to a specific branch OR profile class.
                            </Text>
                        </CardHeader>
                        <CardBody>
                            <VStack 
                                w="100%" 
                                divider={<StackDivider borderColor='gray.200' />}
                                spacing={4}
                                align='stretch'>
                            <Heading size={'sm'}>JDI Representative</Heading>
                            <Select
                                className="basic-single"
                                classNamePrefix="select"
                                placeholder="representative"
                                isLoading={fetching}
                                isClearable
                                isSearchable
                                name="JDI Sales Representative"
                                options={marketers}
                                width={'50vw'}
                                padding={'6px'}
                                onSelect={(e) => setSelectedRep(e.target.value)}
                            />
                            </VStack>

                        </CardBody>
                        <CardFooter w="100%">
                            
                                
                          
                        </CardFooter>
                    </Card>
                </GridItem>
                <GridItem w='100%'>
                    <Card>
                        <CardBody>
                            <VStack 
                                w="100%" 
                                divider={<StackDivider borderColor='gray.200' />}
                                spacing={4}
                                align='stretch'
                            >
                            <Heading size={'md'} textAlign="center">Branch Assignment</Heading>
                                
                                <Select
                                    className="basic-single"
                                    classNamePrefix="select"
                                    isLoading={fetching}
                                    isClearable
                                    isSearchable
                                    name="JDI Sales Representative"
                                    options={branches}
                                    width={'50vw'}
                                    padding={'6px'}
                                    onSelect={(e) => setSelectedBranch(e.target.value)}
                                    placeholder="branch"
                                />
                               
                            </VStack>
                            <VStack 
                                w="100%" 
                                divider={<StackDivider borderColor='gray.200' />}
                                spacing={4}
                                align='stretch'
                            >
                                <Heading>Assign Farms by Profile</Heading>

                                <Duration>
                                    <Select
                                        placeholder="profile"
                                        data={[
                                            { value: 'JDI', label: '100% JDI equipment' },
                                            { value: 'JDI/OPPO', label: 'A mix of JDI equipment & competitors equipment' },
                                            { value: 'OPPO', label: 'No JDI equipment' },
                                            { value: 'NONE', label: 'No equipment' },
                                        ]}
                                        maw={350}
                                        value={profile}
                                        onChange={(e) => setProfile(e.target.value)}
                                        onSelect={filterFarmsByProfile}
                                    />
                                </Duration>
                            </VStack>
                            <VStack 
                                w="100%" 
                                divider={<StackDivider borderColor='gray.200' />}
                                spacing={4}
                                align='stretch'
                            >
                                <Heading size={'md'} textAlign="center">Farm Assignment</Heading>

                                <Select
                                    className="basic-single"
                                    classNamePrefix="select"
                                    placeholder={'farm'}
                                    isLoading={fetching}
                                    isClearable
                                    isSearchable
                                    name="JDI Sales Representative"
                                    options={farms}
                                    width={'50vw'}
                                    padding={'6px'}
                                    onSelect={(e) => setSelectedFarm(e.target.value)}
                                />
                            </VStack>
                        </CardBody>
                        <CardFooter w="100%">
                            <VStack w="100%" divider={<StackDivider borderColor='gray.200' />}
                                spacing={4}
                                align='stretch'
                            >
                                <Button bg={"black"} onClick={updateBranchAssignment}>Update Assignment</Button>
                            </VStack>
                        </CardFooter>
                    </Card>
                </GridItem>
            </Grid>

            <Button 
                size='small' 
                type='reset' 
                onClick={resetAll} 
                style={{color:'orangered', borderRadius:'3px', backgroundColor:'orangered'}}
            >
                    reset
            </Button>


            <br/>
            <br/>
            <p className="tc">*Superusers have the ability to assign single farms to a marketer on the farms table</p>
        </Container>
    );
}

const Container = styled.div`
    width: 100%;
    padding: 10px;
    background-color: ${props=>props.isDark?'#2C3333':'white'};
    color: ${props=>props.isDark?'white':'#2C3333'};

    h3{
        padding-top: 30px;
        font-size: 2rem;
        font-weight: 700;
        text-align: center;
    }

    .tc{
        padding: 30px;
        width: fit-content;
        margin: auto;
    }
`;

const Duration = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
`;

export default Fleet;